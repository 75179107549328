import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as dateFns from 'date-fns';
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';

@Component({
  selector: 'app-gems',
  templateUrl: './gems.component.html',
  styleUrls: ['./gems.component.css']
})
export class GemsComponent implements AfterViewInit {

  currentDate = dateFns.set(new Date(), {
    hours: 9,
    minutes: 45,
    seconds: 0,
    milliseconds: 0
  });
  sliderDate = dateFns.set(new Date(), {
    hours: 9,
    minutes: 45,
    seconds: 0,
    milliseconds: 0
  });

  tabNames = [ 'AOD_443nm', 'NO2', 'O3T', 'HCHO' ];
  regions = ['EAST_ASIA', 'GEMS_FULL', 'KOREA'];

  imageSources = ['', '', ''];

  private activatedPath;

  constructor(private route: ActivatedRoute, private changeRef: ChangeDetectorRef) {
    this.activatedPath = this.route.snapshot.url[0].path;
  }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  readyDate(dateRange: DateRange) {
    this.currentDate = dateRange.current;
    this.sliderDate = dateRange.start;
    this.imageSources = this.generateImageSources();
  }

  changeCurrentDate(dateRange: DateRange) {
    this.currentDate = dateRange.current;
    this.sliderDate = dateRange.start;
    this.imageSources = this.generateImageSources();
  }

  changeDateValue(date: Date) {
    this.sliderDate = date;
    this.imageSources = this.generateImageSources();
  }

  private generateImageSources(): string[] {
    const product = this.tabNames.find(t => t.toLowerCase() == this.activatedPath);

    const formattedDate = dateFns.format(this.sliderDate, "yyyyMMddHH") + '45';
    return this.regions.map(region => {
      const dirs = ['image', dateFns.format(this.currentDate, "yyyy/MM/dd"), 'satellite', 'gems', product.toLowerCase()].join('/');
      const fileName = [product, formattedDate, region].join('_') + '.png';

      return dirs + '/' + fileName;
    });
  }
}
