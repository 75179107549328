<div class="columns my-6 is-fullwidth is-multiline is-mobile has-text-centered is-vertical-align">
    <div class="column">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title" >
                    {{ header }} 
                </p>
                <p class="card-header-icon mr-6 has-text-weight-bold">
                    {{ index+1 }} / {{ this.imageArr.length }}
                </p>
            </header>
            <div class="card-image">
                <figure class="mx-3 my-3">
                    <img [src]="imageUrl">
                </figure>
            </div>
        </div>
    </div>
</div>
