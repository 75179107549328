import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

export interface Control {
  name?: string
  value: number
  checked: boolean
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnChanges {
  @Input()
  controls!: Control[];
 
  @Output()
  controlsChange = new EventEmitter<Control[]>();

  @Output()
  indexChange = new EventEmitter<number>();

  @Input()
  fullwidth = false;

  @Input()
  alignment: 'left' | 'centered' | 'right' = 'left';

  formGroup = this.fb.group({});

  constructor(private fb: FormBuilder) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.controls)
      return;

    this.update();
  }
  private update() {
    let selectedIndex = this.controls?.findIndex(control => control.checked);
    selectedIndex = selectedIndex < 0 || selectedIndex == null ? 0 : selectedIndex;
    try {
      this.formGroup.removeControl('control');
    } catch (_) {
    }
    this.formGroup.addControl('control', new FormControl(selectedIndex, [Validators.required]));
    this.control.valueChanges.subscribe(index => {
      this.controls = this.controls.map((control, i) => {
        control.checked = index == i;
        return control;
      });
      this.controlsChange.emit(this.controls);
      this.indexChange.emit(index);
    });
  }
  private get control() {
    return this.formGroup.get('control') as FormControl;
  }
}
