import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MembersComponent } from './members.component';
import { ListComponent } from './list/list.component';
import { PermittedComponent } from './list/permitted/permitted.component';
import { WaitingComponent } from './list/wating/wating.component';
import { CollapsibleMembersTableComponent } from './list/collapsible-members-table/collapsible-members-table.component';
import { FormComponent } from './form/form.component';
import { TableModule } from 'src/app/common/table/table.module';
import { ModalModule } from 'src/app/common/modal/modal.module';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { CheckRemoveUserModalComponent } from './list/permitted/check-remove-user-modal/check-remove-user-modal.component';

@NgModule({
  declarations: [MembersComponent, ListComponent, PermittedComponent, WaitingComponent, CollapsibleMembersTableComponent, FormComponent, CheckRemoveUserModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ModalModule,
    ValidationModule
  ],
  exports: [MembersComponent]
})
export class MembersModule { }
