import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/http/auth.service';
import { PostService } from 'src/app/http/post.service';
import { Post } from 'src/app/store/schema';
import { Control } from '../../select/select.component';
import { SelectService } from '../../select/select.service';

@Component({
  selector: 'app-resources-table',
  templateUrl: './resources-table.component.html',
  styleUrls: ['./resources-table.component.css']
})
export class ResourcesTableComponent implements OnInit, OnChanges {

  data: any[];
  max = 0;
  current = 1;

  @Input()
  types: number[];

  @Input()
  controlGroup: Control[][];

  private storeKey: string;
  subscription: Subscription;

  constructor(public post: PostService, public router: Router, public route: ActivatedRoute, public auth: AuthService, public select: SelectService) { }

  ngOnInit(): void {
    this.storeKey = this.route.snapshot.data.storeKey ?? this.router.url.split("/")[2];
    this.requestData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.types == null)
      return;
    this.requestData();
  }

  get headers() {
    const headers = ["Title", "Date",  "Writer", "File Download"];
    return this.types == null ? headers : [ 'Type' ].concat(headers);
  }

  open({ id }) {
    this.router.navigate(['view', id], { relativeTo: this.route });
  }

  onPageChange(pageInfo) {
    this.current = pageInfo.current;
    window.scrollTo(0, 0);
    this.requestData();
  }

  requestData() {
    let type = null;
    if (this.types != null && this.types.some(t => t > 0)) {
      type = this.types.reduce((p, r) => p * 10 + r, 0);
    }

    this.subscription?.unsubscribe();
    /* this.subscription = this.post.readAll(this.storeKey, this.current, {
      type: type
    }).subscribe(({ content, totalPages }) => {    
      this.data = content;      
      this.max = totalPages;
    }); */

    this.subscription = this.post.readAll(this.storeKey, this.current, {
      type: type
    }).subscribe((data) => {
      this.data = data.content;

   //   console.log(this.data);

      this.max = data.totalPages;
    });
  }

  getTypes(selectedType: number){
    let types = this.select.decode(selectedType);
    return this.controlGroup?.map((arr, index) => arr[types[index] - 1].name).join(", ");   
  }

  downloadFiles(e: MouseEvent, post: Post) {
    e.stopPropagation();
    e.preventDefault();

    for (const file of post.files) {
      this.post.downloadFile(this.storeKey, post.id, file);
    }
  }

  fileCount(post: Post) {
    return post.files ? post.files.length : 0;
  }

  totalUploadedFileSize(post: Post) {
    return post.files?.map(f => f.size).reduce((p, r) => {
      p += r;
      return p;
    }, 0);
  }
}
