import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-comparison',
  templateUrl: './comparison.component.html'
})
export class ComparisonComponent implements OnInit {

  constructor() { }

  modelInfo:any[] = [
    {"name": "CMAQ-GIST", "team": "GIST"},
    {"name": "CMAQ", "team": "UNIST"},
    // {"name": "WRF-Chem", "team": "UNIST"},
    {"name": "CAMx", "team": "AJOU"},
    {"name": "GEOS-Chem", "team": "SNU"},
    {"name": "ENSEMBLE", "team": ""}
  ]


  ngOnInit(): void {
  }

}
