import { Component, Input } from '@angular/core';

export interface ImageSchema {
  title: string
  src: string
}

@Component({
  selector: 'app-image-columns',
  templateUrl: './image-columns.component.html',
  styleUrls: ['./image-columns.component.css']
})
export class ImageColumnsComponent {

  rowIndices: number[];
  columnIndices: number[][] = [];
  _data: ImageSchema[];
  columnData:{style: string, info: ImageSchema[]}[];

  @Input()
  column: 1 | 2 | 4 | 6 | 12 = 1;

  @Input()
  imageRatio: string = '';

  @Input()
  set data(data: ImageSchema[]){
    if(this.column == undefined || this.column == null) 
      return;

    this.columnData = [];
    this.columnIndices = [];

    this._data = data;
    this.rowIndices = this.getSubColunmsIndices();

    this.rowIndices.map(rowIndex => {
      let col = this.getSubColumns(rowIndex);

      this.columnData.push({
        style: this.getColumnStyle(rowIndex),
        info: col
      }) 

      this.columnIndices.push([...Array(col.length).keys()]);
    });

  }

  @Input()
  restPolicy: 'division' | 'full' = 'division';

  getColumnStyle(subColumnsIndex: number) {
    const subColumns = this.getSubColumns(subColumnsIndex);
    const columnCount = this.column as number;

    if (subColumns.length >= columnCount) {
      return `is-${12 / columnCount}`;
    }
    else {
      return this.restPolicy == 'full' ? 'is-12' : 'is-'+ (12 / this.column);
    }
  }

  getSubColunmsIndices() {
    if (this._data == null || this._data.length <= 0)
      return [];

    return [...Array(Math.ceil(this._data.length / this.column)).keys()];
  }

  getSubColumns(subColumnsIndex: number) {
    const startIndex = subColumnsIndex * (this.column as number);
    let endIndex = startIndex + (this.column as number);
    if (endIndex >= this._data.length) {
      endIndex = this._data.length;
    }

    return this._data.slice(startIndex, endIndex);
  }
}