import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SelectModule } from 'src/app/common/select/select.module';
import { ResourcesTableModule } from 'src/app/common/table/resources-table/resources-table.module';
import { TableModule } from 'src/app/common/table/table.module';
import { AircraftMeasurementsComponent } from './aircraft/aircraft.component';
import { CarMobileDoasComponent } from './car-doas/car-doas.component';
import { GcasMeasurementsComponent } from './gcas/gcas.component';
import { GroundSupersiteComponent } from './ground-supersite/ground-supersite.component';
import { InformationExchangeComponent } from './information-exchange/information-exchange.component';
import { MaxDoasComponent } from './max-doas/max-doas.component';
import { MicroMeteorologicalAnalysisComponent } from './micro-meteorological/micro-meteorological.component';
import { PandoraListComponent } from './pandora-list/pandora-list.component';
import { SatelliteRgbComponent } from './satellite-rgb/satellite-rgb.component';
import { SijaqReportComponent } from './sijaq-report/sijaq-report.component';

@NgModule({
  imports: [
    CommonModule, ResourcesTableModule, TableModule, ReactiveFormsModule, RouterModule, SelectModule
  ],
  declarations: [
    AircraftMeasurementsComponent,
    GcasMeasurementsComponent,
    MaxDoasComponent,
    CarMobileDoasComponent,
    MicroMeteorologicalAnalysisComponent,
    PandoraListComponent,
    InformationExchangeComponent,
    GroundSupersiteComponent,
    SatelliteRgbComponent,
    SijaqReportComponent
  ]
})
export class FieldCampaignDataCollectedModule { }
