<div class="tabs is-toggle is-fullwidth is-small">
  <ul>
    <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabName of tabNames">
      <a [routerLink]="[ '/routine_data_collected', 'satellite-data', 'gems', (tabName | lowercase)]">
        <span>{{ tabName }}</span>
      </a>
    </li>
  </ul>
</div>

<app-time-range-slider [currentDate]="currentDate" [unit]="'m'" [relativeMin]="525" [relativeMax]="946"
  [intervals]="[60]" [interval]="60" [step2]="60" (ready)="readyDate($event)" (valueChange)="changeDateValue($event)"
  (dateChange)="changeCurrentDate($event)">
</app-time-range-slider>

<div class="columns">
  <div class="column" *ngFor="let region of regions; let i = index">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          {{ region }}
        </p>
      </header>
      <div class="card-image">
        <figure class="mx-5 my-5">
          <img [src]="imageSources[i]" />
        </figure>
      </div>
    </div>
  </div>
</div>
