<div class="mx-6">
    <div class="my-6">
        <app-schedule></app-schedule>
    </div>
    <div class="content">
        <h2>1. Data sharing</h2>
        <div class="messageis-dark">
            <div class="message-body">
                <ul>
                    <li>Preliminary data should be shared the day after observation</li>
                    <li>Please add a README file to a repository</li>
                    <li>Each data file should contain header information in the top row and the first column
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <h2>2. Weekly meeting</h2>

        <div class="messageis-dark">
            <div class="message-body">
                <ul>
                    <li>Any information (e.g. preliminary analysis and very recent paper) could be introduced
                        during weekly meeting</li>
                    <li>All participants are encouraged to attend the weekly meeting during the campaign</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <h2>3. Quarantine prevention guidance for the international teams</h2>
        <div class="columns">
            <div class="column">
                <div class="messageis-dark">
                    <div class="message-body">
                        <h3>Before departure</h3>    
                        <ul>
                            <li>Only the vaccinated can be the quarantine exception.</li>
                            <li>Input quarantine information via pre-entry system (Q-code; <a href="https://cov19ent.kdca.go.kr/cpassportal/" target="_blank">https://cov19ent.kdca.go.kr/cpassportal/</a>)</li>
                            <li>Certificate of negative PCR test result for COVID-19.</li>
                        </ul>  
                        
                        <div class="tile is-ancestor has-text-centered has-text-weight-bold">
                            <div class="tile is-parent">
                              <article class="tile is-child notification is-light">
                                  <div class="content">
                                    Input quarantine information via pre-entry system (Q-Code)
                                  </div>
                              </article>
                            </div>
                        </div>    

                        <div class="tile is-ancestor has-text-centered">
                            <div class="tile is-parent">
                                <article class="tile is-child notification has-background-link-light">
                                    <div class="content">
                                    Arrival Information
                                  </div>
                              </article>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification has-background-link-light">
                                    <div class="content">
                                    Vaccination History
                                  </div>
                              </article>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification has-background-link-light">
                                    <div class="content">
                                        Certificate
                                    </div>
                              </article>
                            </div>
                        </div>

                        <div class="tile is-ancestor has-text-centered">
                            <div class="tile is-parent">
                              <article class="tile is-child notification has-background-success-light">
                                    <div class="content">
                                        Direct entry of passport number, etc
                                    </div>
                              </article>
                            </div>
                            <p class="my-6">
                                <i class="fas fa-chevron-circle-right"></i>
                            </p>
                            <div class="tile is-parent">
                                <article class="tile is-child notification has-background-success-light">
                                    <div class="content">
                                        Direct upload of vaccination history
                                    </div>
                              </article>
                            </div>
                            <p class="my-6">
                                <i class="fas fa-chevron-circle-right"></i>
                            </p>
                            <div class="tile is-parent">
                                <article class="tile is-child notification has-background-success-light">
                                    <div class="content">
                                        Required to attach a certificate
                                    </div>
                              </article>
                            </div>
                        </div> 

                        <article class="message is-danger">
                            <div class="message-header has-text-weight-bold">
                                <p>*Criteria for Vaccinated</p>
                            </div>
                            <div class="message-body">
                                <p>1. Second shot (14~180 days since got a second shot)</p>
                                <p>2. Third(booster) shot</p>
                                <p>3. Confirmed case</p>                            
                            </div>
                        </article>                       
                        

                    </div>
                </div>
            </div>
            <div class="column">
                <div class="messageis-dark">
                    <div class="message-body">
                        <h3>After arrival</h3>
                        <ul>
                            <li>One PCR test on departure day.</li>
                            <li>Self diagnosis before get the PCR test results.</li>
                            <li>One more RAT on the sixth or seventh day.</li>
                            <li>Wear a facemask in all indoor spaces (including public transportation) and outdoor spaces where 2-meter distancing is unavailable</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>