import { Component, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-meteogram',
  templateUrl: './meteogram.component.html',
  styleUrls: ['./meteogram.component.css']
})
export class MeteogramComponent implements OnInit {

  ngOnInit(): void {
    this.getImageSources();
  }

  rootUrl = "/gdps";
  headers = [
    ["Seoul", "Baengnyeongdo"],
    ["Seosan", "Pyeongtaek"],
    ["Incheon", "Suwon"],
    ["Paju", "Daejeon"]
  ]

  imageArray: any[];

  getImageSources(){
    this.imageArray = [];

    const cityInfo = {
      "Seoul": "47108",
      "Baengnyeongdo": "47102",
      "Seosan": "47129",
      "Pyeongtaek": "47551",
      "Incheon": "47112",
      "Suwon": "47119",
      "Paju": "47099",
      "Daejeon": "47133"
    }

    for(const [cityName, cityNo] of Object.entries(cityInfo)){
      this.imageArray.push({
        title: cityName,
        src: [this.rootUrl, dateFns.format(new Date(), "yyyyMM"), dateFns.format(new Date(), "yyyyMMdd"), "notype" , "gdps_erly_city_"+cityNo+"_t072_"+dateFns.format(new Date(),"yyyyMMdd")+"00.gif"].join("/"),
      })
    }

    return this.imageArray;

  }

}
