import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/common/table/table.module';
import { RouterModule } from '@angular/router';
import { ResourcesTableComponent } from './resources-table.component';
import { CustomPipeModule } from '../../pipe/custom-pipe.module';

const components = [ResourcesTableComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    CustomPipeModule
  ],
  exports: components
})
export class ResourcesTableModule { }
