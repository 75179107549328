import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiGateway } from './api-gateway';

@Injectable({
  providedIn: 'root'
})
export class OpenApiService extends ApiGateway {

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get apiUrl(): string {
    return "open-api";
  }

  list(value){
    return this.post("list", value);
  } 
  

}
