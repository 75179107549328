<div class="table-container">
    <table class="table is-fullwidth is-hoverable">
        <thead>
            <tr>
                <th class="bgcolor has-text-centered" *ngFor="let header of headers">{{ header }}</th>
            </tr>
        </thead>

        <tbody>
            <tr [ngClass]="{'row': open != null}" *ngFor="let element of data; let i=index" (click)="onOpen(element)">
                <!-- td -->
                <ng-container *ngIf="rowTemplateRef" [ngTemplateOutlet]="rowTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:element, index:i}">
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>