import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { DefaultImageModule } from 'src/app/common/default-image/default-image.module';
import { TableModule } from 'src/app/common/table/table.module';
import { GemsComponent } from './gems.component';

@NgModule({
  declarations: [GemsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TimeRangeSliderModule,
    TableModule,
    DefaultImageModule
  ],
  providers: [
    DatePipe
  ]
})
export class GemsModule { }
