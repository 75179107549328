import { ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-pandora',
  templateUrl: './pandora.component.html',
  styleUrls: ['./pandora.component.css']
})
export class PandoraComponent {

  tabs: string[] = [
    "SNU",
    "UNIST",
    "PNU"
  ];

  current:any = 0;
  currentName:any = this.tabs[0];
  selectedDate: Date = new Date();


  rootUrl = "/finep/";

  imageArray!: any[];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  clickTab(tabid?: number, tabname?:string) {
    this.current = tabid;
    this.currentName = tabname;
    this.makeImageArray();
  }

  titles: string[] = [
    "Hourly NO2 Surface Concentration (KST)",
    "Hourly NO2 Total Vertical Column (KST)",
    "Hourly O3 Total Vertical Column (KST)"
  ]

  imageInfo: any = [
    {name: "Seoul-SNU", key: "P149"},
    {name: "Ulsan", key: "P150"},
    {name: "Busan", key: "P20"} //FIXME 아직 소스 모름. 
  ]

  filenames: string[] = [
    "NO2_Surface",
    "NO2_TCD",
    "O3_TCD"
  ]

  filenames2: string[] = [
    "Surf_NO2",
    "TCN",
    "TCO"
  ]

  makeImageArray(): any{   
    this.imageArray = [];

    const dirs = this.rootUrl+["pandora", dateFns.format(this.selectedDate, "yyyy/MM/dd"), this.imageInfo[this.current].name].join("/")

    this.filenames.map((keyword:string, index:number) => {
      this.imageArray.push({
        title: this.titles[index],
        src: dirs+"/"+[this.imageInfo[this.current].name, this.imageInfo[this.current].key, keyword, "hourly",dateFns.format(this.selectedDate, "yyyyMMdd") +".png"].join("_")
        //src: this.rootUrl+"/"+[this.imageInfo[this.current].name, this.imageInfo[this.current].key, keyword, "hourly",dateFns.format(this.selectedDate, "yyyyMMdd")+".png"].join("_")
      })
    });

    //console.log(this.imageArray);
  }

  getDateChange(date: Date){
    this.selectedDate = date;
    this.makeImageArray();
  }  

  ngOnInit(): void {
  }

}
