import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ContentWithSidebarComponent } from './content-with-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { AdminModule } from './admin/admin.module';
import { NewsModule } from './news/news.module';
import { CustomPipeModule } from 'src/app/common/pipe/custom-pipe.module';
import { RoutineDataCollectedModule } from './routine-data-collected/routine-data-collected.module';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { FieldCampaignDataCollectedModule } from './field-campaign-data-collected/field-campaign-data-collected.module';

@NgModule({
  declarations: [ContentWithSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CustomPipeModule,
    NewsModule,
    RoutineDataCollectedModule,
    FieldCampaignDataCollectedModule,
    AdminModule,
    CopyrightModule
  ]
})
export class ContentWithSidebarModule { }
