<div class="columns">
    <div class="column">

        <div class="buttons has-addons is-right">
            <button class="button" *ngFor="let value of timeChangeAmounts(true)" (click)="changeDateByButton(value)">
                {{ value }}
            </button>
        </div>

    </div>

    <div class="column is-3">
        <app-calendar [(startDate)]="date" [maxDate]="'today'" (ready)="changeDate($event)"
            (event)="changeDate($event)"></app-calendar>
    </div>

    <div class="column">

        <div class="buttons has-addons">
            <button class="button" *ngFor="let value of timeChangeAmounts()" (click)="changeDateByButton(value)" [disabled]="isDisableMoveToFuture()">
                {{ value }}
            </button>
        </div>

    </div>
</div>