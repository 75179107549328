<div class="container">
    <h1 class="title">Profile</h1>

    <div [formGroup]="form">
        <app-input-field [(form)]="form" [controlName]="'email'" [iconClass]="'fa-envelope'" [type]="'email'" [label]="'E-Mail'" [placeholder]="'E-Mail'" [disabled]="true"></app-input-field>
        
        <app-input-field [(form)]="form" [controlName]="'password'" [iconClass]="'fa-key'" [type]="'password'" [invalidMessage]="invalidPasswordMessage"></app-input-field>

        <app-input-field [(form)]="form" [controlName]="'confirmPassword'" [iconClass]="'fa-key'" [type]="'password'" [label]="'Confirm password'" [placeholder]="'Confirm password'"></app-input-field>
        
        <!-- <app-radio-field [values]="nationality.values" [images]="nationality.images" [(form)]="form" [controlName]="'nationality'"></app-radio-field> -->

        <app-input-field [(form)]="form" [controlName]="'name'" [iconClass]="'fa-id-badge'"></app-input-field>

        <app-input-field [(form)]="form" [controlName]="'charge'" [iconClass]="'fa-sitemap'"></app-input-field>

        <app-input-field [(form)]="form" [controlName]="'organization'" [iconClass]="'fa-building'"></app-input-field>

        <app-input-field [(form)]="form" [controlName]="'telephone'" [iconClass]="'fa-phone-alt'" [type]="'tel'"></app-input-field>

        <app-input-field [(form)]="form" [controlName]="'address'" [iconClass]="'fa-map-marker-alt'" [type]="'tel'"></app-input-field>

        <app-radio-field [values]="roles" [(form)]="form" [controlName]="'role'" [disabled]="true"></app-radio-field>
        
        <div class="buttons is-right">
            <a class="button is-link is-light" [routerLink]="[ 'member' ]">Cancel</a>
            <button class="button is-link" [disabled]="!form.valid" (click)="onSubmit()">Submit</button>
        </div>
    </div>
</div>