import { Component, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-south-korea',
  templateUrl: './south-korea.component.html',
  styleUrls: ['./south-korea.component.css']
})
export class SouthKoreaComponent  {

  rootUrl = "/gdps";
  today = dateFns.format(new Date(),"yyyyMMdd");


  headers = ["day +1", "day +2", "day +3", "day +4", "day +5", "day +6"];
  imageArray: any;

  fillZero(num){    
    return num.toString().length < 3 ? '0' + num : num;
  }

  getImageSources(filename){
    this.imageArray = {};

    const filenames = ['wsfc', 'wt85'];

    filenames.map((filename) => {
      let tempArray = [];
      this.headers.map((_h,i)=> {

        tempArray.push({
          title: this.headers[i],
          src: [this.rootUrl, dateFns.format(new Date(), "yyyyMM"), this.today, "notype", "gdps_lc40_wtem_"+filename+"_s"+this.fillZero((i+1)*24)+"_"+this.today+"00.gif"].join("/")
        })
      });
      this.imageArray[filename] = tempArray;
    });

    return this.imageArray[filename];
  }


}
