<section class="hero is-small is-success pt-5 pb-5">
    <div class="columns is-vcentered">
        <div class="column has-text-centered">
            <p class="is-family-primary title has-text-weight-bold is-size-2">SIJAQ 2022 Campaign</p>
            <p class="is-family-primary subtitle has-text-weight-bold pt-3">Main period: May. 2~Aug. 31</p>
        </div>   
    </div>
</section>
<section class="hero mx-6 my-6">
    <p class="title has-text-weight-bold is-family-primary mb-6">
        Purpose of SIJAQ 2022
    </p>
    <div class="subtitle ml-3">
        <div>
            <div class="is-family-primary">
                • Identify PM2.5 formation processes in SMA (especially, nitrate and OA formation)
            </div>
            <div class="is-family-primary">
                • Validate GEMS with fiducial reference measurement (considering spatial representativeness)
            </div>
            <div class="is-family-primary">
                • Monitoring of air pollutants in SMA and Southeastern region
            </div>
            <div class="is-family-primary">
                • Scientific papers → RSSR (Rapid Science Synthesis Report)→ FSSR
            </div>
        </div>
    </div>
</section>
<section class="hero mx-6 my-6">
    <p class="title has-text-weight-bold is-family-primary mb-6">
        Role and responsibility of participating teams
    </p>
    <div class="subtitle ml-3">
        <div>
            <div class="is-family-primary">
                • Follow the COVID-19 control and prevention act
            </div>
        </div>
    </div>
</section>
<section class="hero mx-6 my-6">
    <p class="title has-text-weight-bold is-family-primary mb-6">
        Data collection, storage and sharing
    </p>
    <div class="subtitle ml-3">
        <div>
            <div class="is-family-primary">
                • Use data management system through a webpage (https://www.sijaq.org)
            </div>
        </div>
    </div>
</section>
<section class="hero mx-6 my-6">
    <div class="columns">
        <div class="column">
            <img src="assets/images/home/airborne_ground_chemical_measurements.png">
        </div>
        <div class="column">
            <img src="assets/images/home/ground_based_observation_station.png">
        </div>
    </div>
</section>

