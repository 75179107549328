import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { HomeModule } from './section/home/home.module';
import { ContentWithSidebarModule } from './section/content-with-sidebar/content-with-sidebar.module';
import { AuthModule } from './section/auth/auth.module';
import { CopyrightModule } from '../common/copyright/copyright.module';
import { DateControlsModule } from '../common/calendar/date-controls/date-controls.module';
import { TimeRangeSliderModule } from '../common/calendar/time-range-slider/time-range-slider.module';
import { OthersModule } from './others/others.module';

@NgModule({
  declarations: [MainComponent, NavComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    HomeModule,
    AuthModule,
    ContentWithSidebarModule,
    CopyrightModule,
    DateControlsModule,
    TimeRangeSliderModule,
    OthersModule
  ],
  exports: [MainComponent]
})
export class MainModule { }
