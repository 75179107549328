<div class="field is-grouped is-vcentered">
    <p class="control" *ngFor="let controls of controlGroup; let i=index">
        <app-select [controls]="controls" (indexChange)="changeSelect($event)"></app-select>
    </p>
    <div class="tabs is-small is-toggle mx-3">
        <ul>
          <li class=""><a [routerLink]="['/routine_data_collected/sensing_data/pandora']">View image</a></li>
        </ul>
    </div>
</div>
<app-resources-table [controlGroup]="controlGroup"  [types]="types"></app-resources-table>
