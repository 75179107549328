<div class="tabs is-toggle is-fullwidth is-small">
    <ul>
      <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabName of tabNames">
        <a [routerLink]="[ '/routine_data_collected', 'weather-analysis', (tabName | lowercase)]">
          <span>{{ tabName }}</span>
        </a>
      </li>
    </ul>
</div>
<app-prediction></app-prediction>
<app-meteogram></app-meteogram>
<app-notification></app-notification>