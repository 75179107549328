<div class="tabs is-toggle is-fullwidth is-small">
  <ul>
    <li routerLinkActive="is-active" [ngClass]="" *ngFor="let tabHeader of tabHeaders">
      <a [routerLink]="[ '/routine_data_collected', 'satellite-data', 'cloud', (tabHeader.value)]">
        <span>{{ tabHeader.name }}</span>
      </a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>