import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidatorService } from 'src/app/common/validation/validation.service';
import { AuthService } from 'src/app/http/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { userType, role, nationality, enumToArray } from 'src/app/main/section/auth/userinfo.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  email: string;
  form: FormGroup;

  invalidPasswordMessage = "Must be at least 8 characters, including letters (case sensitive), numbers, and at least 1 special character.";

  nationality = {
    values: enumToArray(nationality),
    images: ["assets/images/flag/korea-flag-small.png", "assets/images/flag/china-flag-small.png", "assets/images/flag/japan-flag-small.png"]
  };

  usertype = {
    values: enumToArray(userType),
    subLabels: []
  };

  roles = enumToArray(role);

  constructor(public auth: AuthService, private fb: FormBuilder, public validation: ValidatorService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.email = this.auth.who();
    this.formgroupInit(null);    
    this.auth.user(this.email).subscribe(userinfo => this.formgroupInit(userinfo));
  }

  formgroupInit(userinfo) {
    const nflag = userinfo == null;

    this.form = this.fb.group({
      id: [nflag ? '' : userinfo['id']],
      email: [nflag ? '' : userinfo['email'], [Validators.required]],
      password: ['', [Validators.required, this.validation.passwordValidator()]],
      confirmPassword: ['', [Validators.required, this.validation.passwordValidator()]],
      // nationality: [nflag ? '' : userinfo['nationality'], [Validators.required]],
      name: [nflag ? '' : userinfo['name'], [Validators.required]],
      charge: [nflag ? '' : userinfo['charge']],
      organization: [nflag ? '' : userinfo['organization']],
      telephone: [nflag ? '' : userinfo['telephone']],
      address: [nflag ? '' : userinfo['address']],
      // usertype: [nflag ? '' : userinfo['usertype'], [Validators.required]],
      role: [nflag ? '' : userinfo['role'].toLowerCase(), [Validators.required]],
      permit: [nflag ? '' : userinfo['permit']]
    },{
      validators: this.validation.match('password', 'confirmPassword')
    });
  }

  onSubmit() {
    const value = this.form.value;
     
    this.auth.update(value).subscribe(_response =>{
      alert("successfully updated");

      }, (error: HttpErrorResponse) =>{
        console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
  }

  
}
