<div [formGroup]="form" class="field field-container is-vcentered">
    <div class="control">
        <label class="is-family-primary has-text-weight-bold mr-3">{{ label || (controlName | titlecase) }}</label>
        <label class="radio" *ngFor="let value of values ; let i = index">
            <input type="radio" class="mr-1" [name]="controlName" [value]="value" [formControlName]="controlName" [attr.disabled]="disabled"
            required>
            <span *ngIf="images"><img [src]="images[i]" class="mr-2"></span>
            <span>{{ subLabels[i] || (value | titlecase) }}</span>
        </label>
    </div>
</div>