import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MainModule } from './main/main.module';
import { StoreService } from './store/store.service';
import { ProgressModule } from './common/progress/progress.module';
import { AuthService } from './http/auth.service';
//import { ArticleService } from './http/article.service';
import { AuthInterceptor } from './http/auth.interceptor';
import { PostService } from './http/post.service';
import { PresentationFilesService } from './http/presentation-files.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    ProgressModule,
    MainModule
  ],
  providers: [
    StoreService,
    AuthService,
    //ArticleService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    PostService,
    PresentationFilesService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
