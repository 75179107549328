import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TimeRangeSliderModule } from 'src/app/common/calendar/time-range-slider/time-range-slider.module';
import { CopyrightModule } from 'src/app/common/copyright/copyright.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { TranslateDirectiveModule } from 'src/app/common/translate-directive/translate-directive.module';
import { ValidationModule } from 'src/app/common/validation/validation.module';
import { UnderConstructionModule } from '../../under-construction/under-construction.module';
import { ModelimageComponent } from './modelimage.component';

@NgModule({
  declarations: [ModelimageComponent],
  imports: [
    CommonModule,
    UnderConstructionModule,
    ValidationModule,
    TranslateDirectiveModule,
    ReactiveFormsModule,
    CopyrightModule,
    TimeRangeSliderModule,
    ImageColumnsModule,
    RouterModule
  ],
  exports: [ModelimageComponent]
})
export class ModelimageModule { }
